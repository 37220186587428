// 
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 200;
  src: url("/fonts/nunitosans/NunitoSans-ExtraLight.ttf");
}
// 
@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 200;
  src: url("/fonts/nunitosans/NunitoSans-ExtraLightItalic.ttf");
}

// 
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/nunitosans/NunitoSans-Light.ttf");
}
// 
@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 300;
  src: url("/fonts/nunitosans/NunitoSans-LightItalic.ttf");
}

// 
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/nunitosans/NunitoSans-Regular.ttf");
}
// 
@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/nunitosans/NunitoSans-Italic.ttf");
}

// 
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  src: url("/fonts/nunitosans/NunitoSans-SemiBold.ttf");
}
// 
@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 600;
  src: url("/fonts/nunitosans/NunitoSans-SemiBoldItalic.ttf");
}

// 
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/nunitosans/NunitoSans-Bold.ttf");
}
// 
@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 700;
  src: url("/fonts/nunitosans/NunitoSans-BoldItalic.ttf");
}

// 
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 800;
  src: url("/fonts/nunitosans/NunitoSans-ExtraBold.ttf");
}
// 
@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 800;
  src: url("/fonts/nunitosans/NunitoSans-ExtraBoldItalic.ttf");
}

// 
@font-face {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/nunitosans/NunitoSans-Black.ttf");
}
// 
@font-face {
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 900;
  src: url("/fonts/nunitosans/NunitoSans-BlackItalic.ttf");
}
